import AdyenCheckout from '@adyen-web-5.59.0';
import '@adyen-web-5.59.0/dist/adyen.css';

import Vue from 'vue';

import { ENV } from '@emobg/web-utils';
import config from '@/config';
import { ADYEN_ENVIRONMENT_CONFIG, DEFAULT_IFRAME_SIZE, IFRAME_ALL_WIDTH } from '../constants/adyen.const';

import AdyenStoreHubNameSpace from './stores/adyenStore';

import {
  authenticateCardDetails,
  confirmRedirect,
  getRedirectPaymentMethod,
  redirectBookingPaymentResult,
  redirectResult,
} from './api/paymentMethodsApi';

const getOriginKey = () => AdyenStoreHubNameSpace.state?.originKey;

export const adyenEnvironment = () => (
  config.data.environment === ENV.production ? ADYEN_ENVIRONMENT_CONFIG.live : ADYEN_ENVIRONMENT_CONFIG.test
);

export const redirectQuery = 'PaymentRedirectResult';
export const redirectConfirmQuery = 'CardRedirectResult';

export const providerHelperMountPoint = 'provider-helper';
export const providerHelperComponent = Vue.component('ProviderHelperComponent', {
  render(createElement) {
    return createElement('div', { attrs: { id: providerHelperMountPoint } });
  },
});

export const createFromAction = async (configuration, response) => {
  const clientKey = getOriginKey();
  const actionConfiguration = {
    ...configuration,
    environment: adyenEnvironment(),
    clientKey,
  };

  const { adyenVersion } = response;

  const checkout = await AdyenCheckout(actionConfiguration);

  const parsedAction = (adyenVersion && adyenVersion > 4) ? response.action : JSON.parse(response.action);

  checkout.createFromAction(parsedAction).mount(`#${providerHelperMountPoint}`);
};

export const storeRedirectPaymentMethod = async (paymentMethodData, customerData) => {
  const { actionUsed } = paymentMethodData;

  const getStoredRedirectPayment = await getRedirectPaymentMethod(paymentMethodData);

  const { reference, redirectResult: storedRedirectResult, type } = getStoredRedirectPayment;

  const authConfig = {
    reference,
    paymentData: null,
    redirectResult: storedRedirectResult,
    type,
    userUuid: customerData.isBusinessInCompanyPays ? null : customerData.userUuid,
    companyUuid: customerData.isBusinessInCompanyPays ? customerData.companyUuid : null,

    actionUsed,
  };

  return authenticateCardDetails(authConfig);
};

export const onAdditionalDetails = async (configuration, response, callbackFn, isIframeAllWidth) => {
  const clientKey = getOriginKey();
  const actionConfiguration = {
    ...configuration,
    environment: adyenEnvironment(),
    clientKey,
    onAdditionalDetails: callbackFn,
  };

  const parsedAction = response.action;

  const checkout = await AdyenCheckout(actionConfiguration);

  let threeDSConfiguration = null;

  if (isIframeAllWidth) {
    threeDSConfiguration = {
      challengeWindowSize: isIframeAllWidth ? IFRAME_ALL_WIDTH : DEFAULT_IFRAME_SIZE,
    };
  }

  checkout.createFromAction(parsedAction, threeDSConfiguration).mount(`#${providerHelperMountPoint}`);
};

export const handleOnAdditionalDetails = (state, _component, response, customerData, version, actionUsed) => {
  const authConfig = {
    reference: response.reference,
    paymentData: response.action.paymentData,

    challengeResult: state.data.details.threeDSResult,
    generatedToken: response.action.token,

    type: customerData.type,
    userUuid: customerData.isBusinessInCompanyPays ? null : customerData.userUuid,
    companyUuid: customerData.isBusinessInCompanyPays ? customerData.companyUuid : null,

    actionUsed,
  };

  return authenticateCardDetails(authConfig, version);
};

export const getRedirectResult = reference => redirectResult(reference);

export const postConfirmRedirect = redirectResponse => confirmRedirect(redirectResponse);

export const getBookingPaymentResult = bookingInvoiceUuid => redirectBookingPaymentResult(bookingInvoiceUuid);
